import React from 'react';
import {Spring, animated} from 'react-spring/renderprops';

const PlayStoreLogo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 23 25"
        height={props.height}
        className={props.className}>
        <Spring
            native
            from={{color: "white"}}
            to={{color: props.dark ? "grey" : "white"}}>
            {({color}) =>
                <animated.path 
                    fill= {color.interpolate(color =>  `${color}`)} 
                    fillRule="nonzero" 
                    d="M.43456 24.08384C.1593 23.79905 0 23.35566 0 22.78145V2.29972c0-.57421.1593-1.0176.43456-1.30239L.50249.93481l11.61238 11.47264v.2709L.50249 24.15099l-.06793-.06715zM16.95387 8.41331l.09136.04978 4.58451 2.57353c1.30836.73629 1.30836 1.94028 0 2.67193l-4.58451 2.57816-.08667.04978-3.87119-3.82614v-.2709l3.8665-3.82614zm-.38089 8.58587L2.88387 24.68736c-.80586.45149-1.5145.39708-1.94906-.05441l11.68031-11.54558 3.95786 3.91181zm0-9.0023l-3.95786 3.91181L.93481.36775c.43456-.4515 1.1432-.50591 1.94906-.05905l13.68911 7.68818z"
                    opacity= "1"
                />
            }
        </Spring>
    </svg>
);

export default PlayStoreLogo;