import React from 'react';
import {css} from 'emotion';
import styled from 'styled-components';
import { Link } from "gatsby";
// import Menu from './Menu';
import DownloadAppButtonMobile from './DownloadAppButtonMobile';
import Moment from 'moment';

const styles = {
    header: css`
        ${tw`w-full flex justify-between items-center`};
        height: 11.5488257108vh;
        @media (min-width: 992px){
            height: 11.5488257108vh;
        } 
    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-evenly items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
}

const Row = styled.div`
     ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
    max-width: 82rem;
    @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
        //Retina Specific
        max-width: 75rem;
    }
`
/*global tw*/
const Header = (props) =>(
    <div className={styles.header}>
        {/* <div className={styles.row}> */}
        <Row>
            <div className={css`${tw`w-1/3 h-full`}`} onClick={props.onClick}>
                <Link 
                    to='/'
                    className={css`${tw`no-underline `}`}>
                    <img className={css`${tw`h-8 lg:h-12`}`} src={require('../SvgComponents/logos/typeface/white.svg')} alt="Zyppys" />
                </Link>
                {/* <p className={css`${tw`font-medium opacity-60 text-white -mt-1`}`}>Rental Car Marketplace</p> */}
            </div>

            {
                props.isLoggedIn ? 
            <div className={css`${tw`w-1/3 h-full text-right pr-0`}`}>
                <p className={css`${tw`font-medium text-sm lg:text-normal text-white tracking-tight cursor-pointer`}`}>{props.name !== "" ? props.name : "MENU"}</p>
            </div>
        :
        <DownloadAppButtonMobile/>}
        </Row>
    </div>
);

export default Header;


// <div className={css`${tw`w-1/3 h-full text-right`}`} onClick={()=>{props.onMenuPress()}}>
//     <p className={css`${tw`font-medium text-sm lg:text-normal text-white tracking-tight`}`}>{props.name !== "" ? props.name : "MENU"}</p>
// </div> 
