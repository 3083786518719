import React from 'react';
import styled from 'styled-components';
import {css} from 'emotion';
import AppleLogo from '../SvgComponents/logos/AppleLogo';
import PlayStoreLogo from '../SvgComponents/logos/PlayStoreLogo';
import {Spring, animated} from 'react-spring/renderprops';

/* global tw */

const Wrapper = styled.a`
    ${tw`flex flex-col items-center justify-end relative overflow-hidden no-underline py-1 px-1 rounded cursor-pointer`};
    ${props => props.dark ? tw`text-black` : tw`text-white` };
    ${props => props.dark ? tw`border-color-black` : tw`border-color-white` };
    border: 0px solid;
    ${props => props.hide && tw`flex lg:hidden xl:hidden`};
`
class DownloadAppButtonMobile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hovered: false,
        }
        this.handleClick = this.handleClick.bind(this)
    }
    handleClick = () => {
        console.log("clicked",window)
        // eslint-disable-next-line
        analytics.track('Download App Clicked ', { category: 'Download Button', label: 'Clicked' });
    }
    render(){
        return(
            <Wrapper hide={this.props.hide} className={this.props.className} onClick={()=>{this.handleClick()}} dark={this.props.dark} href={
            typeof window !== "undefined" && 
                window.navigator.platform === "Win32" ? "https://play.google.com/store/apps/details?id=com.zyppys.customer" : "http://onelink.to/zyppys"} target="_blank">
                    <div className={css`${tw`h-7`}`} onClick={()=>{this.handleClick()}}>
                        <PlayStoreLogo dark={this.props.dark} height="16" className={css`${tw`mx-2`}`}/>
                        <AppleLogo dark={this.props.dark} height="16" className={css`${tw`mx-2`}`}/>
                    </div>
                    <div className={css`${tw`text-xs self-end`}`}>
                    {/* &#8377;250 off on App */}
                    Download App
                    </div>
            </Wrapper>
        );
    }
}

export default DownloadAppButtonMobile;