import React from 'react';
import {Spring, animated} from 'react-spring/renderprops';

const AppleLogo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 23"
        height={props.height}
        className={props.className}
        >
        <Spring
            native 
            from={{color: "white"}}
            to={{color: props.dark ? "grey" : "white"}}>
            {({color}) =>
                <animated.path 
                    fill= {color.interpolate(color =>  `${color}`)} 
                    fillRule="nonzero" 
                    d="M15.387657 12.04243c.00214 1.995937 1.197434 3.7971994 3.03571 4.5747-.353539 1.1484888-.886453 2.2338535-1.579 3.21589-.93008 1.39128-1.90523 2.75051-3.453 2.77563-1.50392.03471-2.00933-.88506-3.73427-.88506-1.74109 0-2.2798.85994-3.7222.91977-1.47401.05459-2.6005-1.48492-3.56438-2.86315-1.92651-2.81461-3.42662-7.932-1.41551-11.414.94441-1.6970827 2.71022-2.773985 4.65137-2.8367 1.47534-.03035 2.8454.99637 3.75247.99637.88987 0 2.58276-1.22822 4.329-1.04492 1.65979.0519305 3.197921.883466 4.15039 2.24376-1.50298.9240159-2.427849 2.5535565-2.45058 4.31771zm-2.8406-8.41254c-.818743 1.0183252-2.059311 1.6048794-3.36589 1.59143-.083094-1.2768246.344765-2.5343139 1.18934-3.4955C11.225812.7516143 12.416761.1354149 13.706057 0c.100676 1.3146751-.315089 2.6168152-1.159 3.62989z" 
                    opacity="1" 
                />
            }

        </Spring>
    </svg>
);

export default AppleLogo;